
import { defineComponent } from 'vue'
import CampaignsSidebar from '@/components/pages/campaigns/CampaignsSidebar.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    CampaignsSidebar,
    TmButton,
  },
})
