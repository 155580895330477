
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmToggleButtons from '@/components/shared/TmToggleButtons.vue'
import PageContentSidebarList from '@/components/layout/pageContent/sidebar/PageContentSidebarList.vue'
import PageContentSidebarHr from '@/components/layout/pageContent/sidebar/PageContentSidebarHr.vue'
import PageContentSidebarListTitle from '@/components/layout/pageContent/sidebar/sidebarItem/PageContentSidebarListTitle.vue'
import { campaignsSmsSidebarRoutes, campaignsEmailsSidebarRoutes, campaignsSmsLinksSidebarRoutes, campaignsEmailsLinksSidebarRoutes } from '@/router/sidebarRoutes/campaignsRoutes'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: {
    TmButton,
    TmToggleButtons,
    PageContentSidebarList,
    PageContentSidebarHr,
    PageContentSidebarListTitle,
  },
  setup() {
    const route = useRoute()
    return {
      route,
      campaignsSmsSidebarRoutes,
      campaignsSmsLinksSidebarRoutes,
      campaignsEmailsSidebarRoutes,
      campaignsEmailsLinksSidebarRoutes,
    }
  },
})
