import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_campaigns_sidebar = _resolveComponent("campaigns-sidebar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view, { "menu-title": "Campaigns" }, {
    default: _withCtx(({ Component }) => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(Component), null, {
        "filter-bar-dropdown": _withCtx(() => [
          _createVNode(_component_tm_button, {
            flat: "",
            color: "transparent",
            "icon-only": "",
            size: "medium",
            class: "mr-1 mt-1",
            to: { name: 'base.campaigns' }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_tm_icon, {
                name: "tmi-settings-outline",
                class: "gray8--text",
                size: "xLarge"
              })
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        "filter-bar-slot": _withCtx(() => [
          _createVNode(_component_campaigns_sidebar)
        ]),
        _: 2
      }, 1024))
    ]),
    _: 1
  }))
}